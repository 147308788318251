import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Slider from '../components/slider';
import SEO from '../components/seo';

const PhotoProject = ({ data }) => {
  const { content, chapter, slug } = data.photoProject;
  const { backgroundColor } = data.photoPage;

  const slugLink = chapter[0]
    ? `/photo/${slug.current}/${chapter[0]?.slug.current}`
    : false;

  return (
    <Layout color={backgroundColor.hex}>
      <SEO siteTitle="photo" />
      <Slider content={content} slugLink={slugLink} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    photoProject: sanityPhotoProject(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      chapter {
        slug {
          current
        }
      }
      content {
        ... on SanityMainImage {
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          portrait
          square
          spacing
          credits
        }
        ... on SanityImageGallery {
          images {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          spacing
        }
        ... on SanityMainText {
          _rawText
          spacing
        }
      }
    }
    photoPage: sanityPhotosPage {
      backgroundColor {
        hex
      }
    }
  }
`;

PhotoProject.propTypes = {
  data: PropTypes.object,
};

export default PhotoProject;
